<template>
    <div class="animate__animated animate__fadeIn">
        <post-page
                :is_mobile_search="c_is_mobile_search"
                :posts="posts"
                :no_posts_left="no_posts_left"
                :no_posts_available="no_posts_available"
                :loading="loading"
                @search="set_search_term"
                @post_created="refresh_posts"
                @post_not_found="remove_post_from_posts"
                @voted="update_post">
        </post-page>
    </div>
</template>

<script>
    import offerpage from "./offer-page"


    export default {
        extends: offerpage,
        watch  : {
            newest_post_id() {
                this.$store.notification.last_ids.all_giveaways = this.newest_post_id;
                this.func.set_localstoragedata('notifications', JSON.stringify(this.$store.notification));
            }
        },
        methods: {
            build_postdata() {
                let lastid    = this.posts.length > 0 ? this.posts[this.posts.length - 1].ID : "";  // Oldest ID thats found in posts
                let services  = this.$user.user.hidden_services !== undefined && this.$user.user.hidden_services.length !== 0 ? this.$user.user.hidden_services.join(',') : "";
                let itemtypes = this.$user.user.hidden_itemtypes !== undefined && this.$user.user.hidden_itemtypes.length !== 0 ? this.$user.user.hidden_itemtypes.join(',') : "";

                return {
                    func              : "get_posts",
                    userid            : this.$user.user.id,
                    max_posts_per_load: this.$variables.v.max_posts_per_load,
                    offer_type        : this.$variables.v.offer_types.giveaway,
                    search            : this.search_term.trim(),
                    lastid            : lastid,
                    excluded_services : services,
                    excluded_itemtypes: itemtypes,
                    point_requirement : "",
                    expired_clicks    : this.$variables.v.post_expiration_clicks_required_for_expired,
                };
            },
        },
        metaInfo() {
            return {
                title: `${this.$t('meta.page-title-unformated')} - ${this.$t('meta.page-title-all-giveaways')}`,
                meta : [{
                    name   : "description",
                    content: this.$t("meta.description"),
                }]
            }
        },
    }
</script>